import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { PropeldPermissionDirectiveModule } from "../../../lib/directive/account-permission/module";
import { AccountComponentModule } from "../account/module";
import { AppHeaderModule } from "../app-header/module";
import { LayoutComponent } from "./component";

@NgModule({
	declarations: [
		LayoutComponent
	],

	exports: [
		LayoutComponent
	],

  imports: [
    RouterModule,
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,

    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
    MatCheckboxModule,
    AccountComponentModule,
    AppHeaderModule,
    PropeldPermissionDirectiveModule
  ]
})
export class LayoutModule {
}
