import { animate, state, style, transition, trigger } from "@angular/animations";
import { Location } from "@angular/common";
import { Component, computed, effect, inject, Injector } from "@angular/core";
import { MatIconModule } from '@angular/material/icon';
import { AppState } from "../../../lib/service/app-state/app-state";
import { SnackbarService } from "../../../lib/service/snackbar/service/snackbar.service";
import { SourcePageStateStore } from "../../../lib/source/state/source-page-state-store";
import {
	CANDIDATE_VIEW_ALL_PUBLISHED,
	COMPANY_UPDATE_PERMISSION,
	DASHBOARD_VIEW_PERMISSION,
	EDUCATIONLEVEL_UPDATE_PERMISSION,
	JOBTITLE_UPDATE_PERMISSION,
	USER_CREATE_PERMISSION,
	USER_UPDATE_PERMISSION
} from "../../core/model/permissions";
import { AccountService } from "../../service/account-service/account-service";

/*
 * animation: sideNaveAnimation
 * trigger: 'openClose'
 *
 * comments: sets the width of an element to 200px when 'open' and to 60px
 *   when closed.  Animates in between these two states over '0.3s'
 */

export const sideNavAnimation = trigger('openCloseSidenav', [
  // ...
  state('open', style({ width: '270px' })),
  state('closed', style({ width: '0px' })),
  transition('open <=> closed', [
    animate('0.2s ease-in-out')
  ]),
]);

/*
 * animation: sideNavContainerAnimation
 * trigger: 'openCloseSidenavContent'
 *
 * comments: Sets the margin-left to 201px when "open" and 61px when "closed".
 */

// export const sideNavContainerAnimation = trigger('openCloseSidenavContent', [
//   state('open', style({
//     'margin-left': '270px',
//   })),
//   state('closed', style({
//     'margin-left': '0px',
//   })),
//   transition('open <=> closed', [
//     animate('0.2s ease-in-out')
//   ]),
// ]);


export const titleAnimation = trigger('titleAnimation', [
  state('open', style({
    'margin-left': '32px'
  })),
  state('closed', style({
    'margin-left': '0px'
  })),
  transition('open <=> closed', [
    animate('0.2s ease-in-out')
  ]),
]);

export const menuButtonAnimation = trigger('menuButtonAnimation', [
  state('open', style({ 'opacity': 0 })),
  state('closed', style({ 'opacity': 1 })),
  transition('open <=> closed', [
    animate('0.2s')
  ]),
]);


@Component({
	selector: "app-layout",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"],
  animations: [sideNavAnimation, titleAnimation, menuButtonAnimation]

})
export class LayoutComponent {

  private location = inject(Location);
  protected readonly navigator = navigator;
  private snackBar =inject(SnackbarService);
  private sourceStateStore = inject(SourcePageStateStore);

  appState = inject(AppState);
  account$ = inject(AccountService);
  injector = inject(Injector);


  isMobile = computed( () => this.appState.state.isMobile());
  //isOpen = signal<boolean>(false);

  constructor() {
    effect( () => {
      if(!this.appState.state.isMobile()) {
        this.appState.setMenuOpen(true);
      } else {
        this.appState.setMenuOpen(false);
      }
    }, {allowSignalWrites: true});
  }

  clear(sourceName: string) {
    this.sourceStateStore.resetState(sourceName);
  }

  toggle(opened: boolean | undefined) {
    // if(this.isMobile()) {
      if(opened !== undefined) {
        this.appState.setMenuOpen(opened);
      } else {
        this.appState.setMenuOpen(!this.appState.isMenuOpen$());
      }
    // }
  }

  navigateBack() {
    this.location.back();
  }

  protected readonly DASHBOARD_VIEW_PERMISSION = DASHBOARD_VIEW_PERMISSION;
  protected readonly USER_UPDATE_PERMISSION = USER_UPDATE_PERMISSION;
  protected readonly COMPANY_UPDATE_PERMISSION = COMPANY_UPDATE_PERMISSION;
  protected readonly EDUCATIONLEVEL_UPDATE_PERMISSION = EDUCATIONLEVEL_UPDATE_PERMISSION;
  protected readonly JOBTITLE_UPDATE_PERMISSION = JOBTITLE_UPDATE_PERMISSION;
	protected readonly USER_CREATE_PERMISSION = USER_CREATE_PERMISSION;
	protected readonly CANDIDATE_VIEW_ALL_PUBLISHED = CANDIDATE_VIEW_ALL_PUBLISHED;
}
